import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'

import {axios} from '../../helpers'

const DeleteAlbum = ({album}) => {
  return (
    <a
      className='Breadcrumb__right__a'
      onClick={() => {
        if (confirm('Are you sure?')) {
          axios.delete(`/users/albums/${get(album, 'id')}.json`).then(() => {
            window.location.href = '/albums'
          })
        }
      }}
    >
      Delete album
    </a>
  )
}

DeleteAlbum.propTypes = {}

export default DeleteAlbum
