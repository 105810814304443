import React from 'react'
import PropTypes from 'prop-types'
import {get, map, drop} from 'lodash'
import className from 'classnames'
import axios from 'axios' // This uses `formData`, so we'll manually do it.

const AlbumUpload = ({album}) => {
  const [data, dataSet] = React.useState([])
  const [isProcessing, isProcessingSet] = React.useState(false)
  const uploadingIndex = React.useRef(0)

  const addFiles = (files) => {
    dataSet([
      ...data,
      ...map(files || [], (x) => {
        return {
          _key: `${get(x, 'name')}-${get(x, 'lastModified')}`,
          file: x,
        }
      }),
    ])
  }

  const uploadFiles = (file) => {
    const formData = new FormData()

    formData.append(
      'authenticity_token',
      document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    )

    try {
      // Some files with strange name, e.g. `å©êœàÀóäèë9_Redacted.pdf`,
      // cannot be uploaded somehow. This fixes it, even though the name is still the same.
      // I.E. magic, do not touch.
      formData.append(
        'file',
        new File([get(file, 'file')], get(file, 'file.name'), {
          type: get(file, 'file.type'),
        })
      )
    } catch (_e) {
      // IE does not support constructor of File API
      // but it's okay with strange name. So I guess we're lucky.
      formData.append('file', get(file, 'file'), get(file, 'file.name'))
    }

    axios
      .post(`/users/albums/${get(album, 'id')}/upload`, formData)
      .then(() => {
        uploadingIndex.current = uploadingIndex.current + 1
        dataSet(drop(data)) // This is an async operation, so let useEffect deal with it.
      })
  }

  React.useEffect(() => {
    if (isProcessing) {
      if (data[0]) {
        uploadFiles(data[0])
      } else {
        window.location.href = `/albums/${get(album, 'id')}`
      }
    }
  }, [get(data, 'length')])

  return (
    <div className='AlbumUpload' disabled={isProcessing}>
      <div className='AlbumUpload__form'>
        <p className='AlbumUpload__h3'>Upload photos to album</p>

        <label className='AlbumUpload__label'>
          <span className='AlbumUpload__label__span'>
            Select photos to upload
          </span>

          <input
            type='file'
            className='AlbumUpload__label__file'
            multiple
            onChange={(e) => {
              addFiles(get(e, 'target.files'))
            }}
          />
        </label>

        {get(data, 'length') > 0 && (
          <div className='Form'>
            <button
              className='Button'
              onClick={() => {
                isProcessingSet(true)
                uploadFiles(data[0])
              }}
            >
              Upload {get(data, 'length', 0)} files
            </button>
          </div>
        )}
      </div>

      <div className='Images'>
        <div className='Images__wrapper'>
          {data.map((x, i) => (
            <div className='Images__image' key={i}>
              <svg viewBox='0 0 8 6' className='Images__image__svg' />

              <div
                className='Images__image__a'
                style={{
                  backgroundImage: `url(${URL.createObjectURL(
                    get(x, 'file')
                  )})`,
                }}
              />

              <a
                className='Images__image__remove'
                onClick={() => {
                  dataSet(data.filter((_, j) => i != j))
                }}
              >
                Remove
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

AlbumUpload.propTypes = {}

export default AlbumUpload
