import React from 'react'
import PropTypes from 'prop-types'
import {get, round} from 'lodash'
import className from 'classnames'

const PhotoDetails = ({photo, image, faces}) => {
  const $img = React.useRef()
  const [ratio, ratioSet] = React.useState(100)

  return (
    <div className='PhotoDetails'>
      <img className='PhotoDetails__img' src={image} ref={$img} />

      <div className='PhotoDetails__boxes'>
        {(faces || []).map((x, i) => (
          <a
            key={i}
            href={`/albums/${get(photo, 'album.id')}/faces/${get(x, 'id')}`}
            className='PhotoDetails__boxes__box'
            data-x={get(x, 'bounding_box')}
            style={{
              top: `${get(x, 'bounding_box.y1') * ratio}%`,
              left: `${get(x, 'bounding_box.x1') * ratio}%`,
              width: `${(get(x, 'bounding_box.x2') -
                get(x, 'bounding_box.x1')) *
                ratio}%`,
              height: `${(get(x, 'bounding_box.y2') -
                get(x, 'bounding_box.y1')) *
                ratio}%`,
            }}
          >
            <span className='PhotoDetails__boxes__box__span'>
              {round(get(x, 'bounding_box.confidence'), 4)}
            </span>
          </a>
        ))}
      </div>
    </div>
  )
}

PhotoDetails.propTypes = {}

export default PhotoDetails
