import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
// import {Link} from 'react-router-dom'

const SearchModal = ({album}) => {
  const [data, dataSet] = React.useState({
    isActive: false,
  })

  const [token, tokenSet] = React.useState()

  React.useEffect(() => {
    tokenSet(
      document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    )
  }, [])

  return (
    <React.Fragment>
      <a
        className='Hero__header__a'
        onClick={() => {
          dataSet({
            ...data,
            isActive: true,
          })
        }}
      >
        Search for images in this album
      </a>

      <div
        className={className('Modal', {
          'Modal--active': get(data, 'isActive'),
        })}
      >
        <a
          className='Modal__overlay'
          onClick={() => {
            dataSet({
              ...data,
              isActive: false,
            })
          }}
        />

        <div className='Modal__wrapper'>
          <a
            className='Modal__close'
            onClick={() => {
              dataSet({
                ...data,
                isActive: false,
              })
            }}
          />

          <h3 className='Modal__h3'>Search for images</h3>

          <p className='Modal__p'>
            Please upload an image as a base image. <br />
            It will be used for searching for similar images in the album.
          </p>

          <form
            className='Form'
            encType='multipart/form-data'
            action={`/albums/${get(album, 'id')}/search`}
            method='post'
            onSubmit={(e) => {
              if (!get(e, 'target.elements.file.files[0]')) {
                e.preventDefault()
              }
            }}
          >
            <input
              type='hidden'
              name='authenticity_token'
              value={token || ''}
            />

            <label className='Label'>
              <span className='Label__span'>File</span>
              <input className='Input' type='file' name='file' required />
            </label>

            <button className='Button'>Submit</button>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

SearchModal.propTypes = {}

export default SearchModal
