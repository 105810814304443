import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'

import {axios} from '../../helpers'

const SignOut = ({}) => {
  return (
    <a
      onClick={() => {
        axios
          .delete(`/users/sign_out`)
          // `DELETE /users/sign_out` will attempt to redirect, which would eventually result in 404.
          // This is a bit hacky, but it works.
          // For a more formal solution we may need something like https://stackoverflow.com/a/31291519
          .finally(() => {
            window.location.href = '/users/sign_in'
          })

        // Still, IE11 is not happy about that, so just time this manually.
        window.setTimeout(() => {
          window.location.href = '/users/sign_in'
        }, 2000)
      }}
    >
      Sign out
    </a>
  )
}

SignOut.propTypes = {}

export default SignOut
