import {get, entries, nth} from 'lodash'
import * as _axios from 'axios'

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key, args = {}) => {
  try {
    let text = get(gon.locales, `${gon.currentLocale}.${key}`, key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        return result.replace(`%{${nth(current, 0)}}`, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {}
}

const authenticity_token = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute('content')

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url, data) => {
    return _axios.get(url, {
      ...data,
    })
  },

  post: (url, data) => {
    return _axios.post(url, {
      authenticity_token,
      ...data,
    })
  },

  patch: (url, data) => {
    return _axios.patch(url, {
      authenticity_token,
      ...data,
    })
  },

  delete: (url, data) => {
    return _axios.delete(url, {
      data: {
        authenticity_token,
        ...data,
      },
    })
  },
}
